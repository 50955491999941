<template>
  <div class="stats">
    <div class="pt-2 pb-2 shadow-sm" :style="tabStyle">
      <span :style="labelStyle" class="text-center font-weight-bolder text-monospace text-uppercase">{{ label }}</span>
    </div>

    <h1 class="text-center mt-5 stat-font"
        :style="valueStyle">{{ value }}
    </h1>
  </div>
</template>

<script>
  export default {
    name: "Statistic",
    computed: {
      tabStyle() {
        return 'background-image: linear-gradient(180deg, ' +
                this.startColor + ', ' +
                this.middleColor + ', ' +
                this.middleColor + ', ' +
                this.endColor + ')'
      },
      labelStyle() {
        return 'color: ' + this.labelColor
      },
      valueStyle() {
        return 'color: ' + this.textColor
      }
    },
    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        type: Number,
        required: true
      },
      startColor: {
        type: String,
        required: true
      },
      middleColor: {
        type: String,
        required: true
      },
      endColor: {
        type: String,
        required: true
      },
      textColor: {
        type: String,
        required: true
      },
      labelColor: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

@media (max-width: 500px) {
	.stats {
		font-size: .6rem;
	}

	.stat-font {
		font-size: 2rem;
	}
}

  .bg-grad-1 {
    background-image: linear-gradient(45deg, red, #8b0000, red);
  }
  .bg-grad-2 {
    background-image: linear-gradient(180deg, mediumvioletred, orange, mediumvioletred);
  }
  .bg-grad-3 {
    background-image: linear-gradient(180deg, darkblue, blueviolet);
  }

  .bg-grad-4 {
    background-image: linear-gradient(180deg, darkgreen, limegreen);
  }
  .bg-grad-5 {
    background-image: linear-gradient(180deg, darkred, mediumvioletred);
  }
  .bg-grad-6 {
    background-image: linear-gradient(180deg, darkblue, blueviolet);
  }

  .bg-grad-7 {
    background-image: linear-gradient(180deg, darkgreen, limegreen);
  }
  .bg-grad-8 {
    background-image: linear-gradient(180deg, darkred, mediumvioletred);
  }
  .bg-grad-9 {
    background-image: linear-gradient(180deg, darkblue, blueviolet);
  }

</style>
