<template>
  <div class="about">
    <h1>This is an about page</h1>
<!--    <hamburger-menu background-class="bg-info"></hamburger-menu>-->
    <div class="container">
      <div class="row">
        <div class="col-4">
          <statistic start-color="limegreen" middle-color="green" end-color="limegreen" text-color="green"></statistic>
        </div>
        <div class="col-4">
          <statistic color-class="bg-grad-2"></statistic>
        </div>
        <div class="col-4">
          <statistic color-class="bg-grad-3"></statistic>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <statistic color-class="bg-grad-4"></statistic>
        </div>
        <div class="col-4">
          <statistic color-class="bg-grad-5"></statistic>
        </div>
        <div class="col-4">
          <statistic color-class="bg-grad-6"></statistic>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <statistic color-class="bg-grad-7"></statistic>
        </div>
        <div class="col-4">
          <statistic color-class="bg-grad-8"></statistic>
        </div>
        <div class="col-4">
          <statistic color-class="bg-grad-9"></statistic>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import HamburgerMenu from '@/components/HamburgerMenu'
  import Statistic from '@/components/Statistic'
  export default {
    components: { Statistic, HamburgerMenu }
  }
</script>
